import { policlinicConfigConstants } from "../actions/constants";

const initState = {
  policlinicScreen: null,
  doctorPoliList: null,
  poliList: [],
  loading: false,
  error: null,
  doctorQueueTables: null
};

export default (state  = initState, action) => {
  switch (action.type) {
    case policlinicConfigConstants.GET_POLICLINIC_SCREEN_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case policlinicConfigConstants.GET_POLICLINIC_SCREEN_SUCCESS:
      state = {
        ...state,
        policlinicScreen: action.payload,
        loading: false
      };
      break;
    case policlinicConfigConstants.GET_POLICLINIC_SCREEN_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false
      };
      break;
    case policlinicConfigConstants.CREATE_POLICLINIC_SCREEN_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case policlinicConfigConstants.CREATE_POLICLINIC_SCREEN_SUCCESS:
      state = {
        ...state,
        policlinicScreen: action.payload,
        loading: false
      };
      break;
    case policlinicConfigConstants.CREATE_POLICLINIC_SCREEN_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false
      };
      break;
    case policlinicConfigConstants.UPDATE_POLICLINIC_SCREEN_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case policlinicConfigConstants.UPDATE_POLICLINIC_SCREEN_SUCCESS:
      state = {
        ...state,
        policlinicScreen: action.payload,
        loading: false
      };
      break;
    case policlinicConfigConstants.UPDATE_POLICLINIC_SCREEN_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false
      };
      break;
    case policlinicConfigConstants.DELETE_POLICLINIC_SCREEN_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case policlinicConfigConstants.DELETE_POLICLINIC_SCREEN_SUCCESS:
      state = {
        ...state,
        policlinicScreen: action.payload,
        loading: false
      };
      break;
    case policlinicConfigConstants.DELETE_POLICLINIC_SCREEN_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false
      };
      break;
    case policlinicConfigConstants.GET_ALL_DOCTOR_POLI_LIST_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case policlinicConfigConstants.GET_ALL_DOCTOR_POLI_LIST_SUCCESS:
      state = {
        ...state,
        doctorPoliList: action.payload,
        loading: false
      };
      break;
    case policlinicConfigConstants.GET_ALL_DOCTOR_POLI_LIST_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false
      };
      break;
      case policlinicConfigConstants.GET_ALL_POLI_LIST_REQUEST:
        state = {
          ...state,
          loading: true
        };
        break;
      case policlinicConfigConstants.GET_ALL_POLI_LIST_SUCCESS:
        state = {
          ...state,
          poliList: action.payload.list,
          loading: false
        };
        break;
      case policlinicConfigConstants.GET_ALL_POLI_LIST_FAILURE:
        state = {
          ...state,
          error: action.payload,
          loading: false
        };
        break;
    case policlinicConfigConstants.GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case policlinicConfigConstants.GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_SUCCESS:
      state = {
        ...state,
        doctorQueueTables: action.payload,
        loading: false
      };
      break;
    case policlinicConfigConstants.GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false
      }
    default:
      break;
  }
  return state;
}