import axiosInstance from "../helpers/axios";
import { bedConstants, ERROR_RESPONSE } from "./constants";


export const getAllBed = (id) => {
    return async (dispatch) => {
      dispatch({ type: bedConstants.GET_BED_LIST_REQUEST });
  
      const res = await axiosInstance.get(`/api/outbound/get-class-room-ipd/${id}`).catch((err) => {
        return dispatch({
          type: bedConstants.GET_BED_LIST_FAILURE,
          payload: err.response?.data || ERROR_RESPONSE,
        });
      });
  
      if (res.status === 200) {
        dispatch({
          type: bedConstants.GET_BED_LIST_SUCCESS,
          payload: res.data.response,
        });
  
        return res.data;
      }
    };
  };