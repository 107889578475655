// let apiUrl = "";

// if (process.env.REACT_APP_NODE === "production") {
//   apiUrl = "https://his-queue-api.aido.id";
// } else if (process.env.REACT_APP_NODE === "staging") {
//   apiUrl = "https://his-queue-api.aido.id";
// } else {
//   apiUrl = process.env.REACT_APP_API_URL_QUEUE_SERVICE || "http://localhost:3006";
// }

let apiUrl = process.env.REACT_APP_API_URL_QUEUE_SERVICE || "http://localhost:3006";
let socketBaseUrl = process.env.REACT_APP_SOCKET_BASE_URL_QUEUE || apiUrl;
let socketPathUrl = process.env.REACT_APP_SOCKET_PATH_QUEUE || "/socket.io/";
let secondaryLogo = process.env.REACT_APP_SECONDARY_LOGO || "";

export const api = `${apiUrl}`;
export const socketBase = `${socketBaseUrl}`;
export const socketPath = `${socketPathUrl}`;
export const logoSecondary = `${secondaryLogo}`;
