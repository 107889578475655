import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllCounter, getAllQueueMachine } from "../../actions";
import Button from "../../components/Button";
import Header from "../../components/Header";
import Modal from "../../components/Modal";
import Select from "../../components/Select";
import "./index.css";
import { allQueueMachine } from "../../actions/constants";
import { isAntrol } from "../../helpers/kioskConfigs";
import { renderModalWarning } from "../../components/RenderModal";

const Home = () => {
  const [counterId, setCounterId] = useState("");
  const [detailData, setDetailData] = useState(null);
  const [path, setPath] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalWarning, setModalWarning]= useState(false)
  const [warningMessage, SetWarningMessage] = useState()

  const queueMachine = useSelector((state) => state.queueMachine);
  const counter = useSelector((state) => state.counter);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const emptyModal = ()=>{
    setModalWarning(false)
    SetWarningMessage()
  }

  useEffect(() => {
    dispatch(getAllQueueMachine()).catch((err) => {
      console.log(err);
    });

    dispatch(getAllCounter()).catch((err) => {
      console.log(err);
    });
  }, []);

  const renderModal = () => {
    return (
      detailData && (
        <Modal
          size="md"
          show={modal}
          handleClose={() => setModal(false)}
          title="Pilih Loket"
        >
          <Row className="m-0 mb-5">
            <Col>
              <Select
                value={counterId}
                onChange={(e) => setCounterId(e.target.value)}
                defaultOption
                defaultOptionTitle="Pilih Loket"
                data={detailData}
              />
            </Col>
          </Row>

          <Row className="m-0 mb-2">
            <Col>
              <Button
                title="Masuk"
                onClick={() =>{
                  if(counterId){
                    navigate(`${path}/${counterId}`)
                  }else{
                    setModalWarning(true)
                    SetWarningMessage('Silahkan pilih Loket')
                  }
                }}
              />
            </Col>
          </Row>

          <Row className="m-0">
            <Col>
              <Button title="Batal" onClick={() => setModal(false)} />
            </Col>
          </Row>
        </Modal>
      )
    );
  };

  const handleShowModal = async (data, path) => {
    let newData = [];

    await data.map((data) => {
      newData.push({
        value: data._id,
        title: data.name,
      });
    });

    setDetailData(newData);
    setPath(path);
    setModal(true);
  };

  const handleNavigate =(machineId, slug) => {
   if(isAntrol){
    switch (slug) {
      case allQueueMachine.PHARMACY_SLUG:
        navigate(`/queue-display/pharmacy/${machineId}`);
        break;
      case allQueueMachine.ADMISSION_SLUG:
        navigate(`/queue-display/admission/${machineId}`);
        break;
      case allQueueMachine.POLICLINIC_SLUG:
        navigate(`/queue-display/policlinic/${machineId}`);
        break;
      case allQueueMachine.BED_SLUG:
      navigate(`/queue-display/bed-config/${machineId}`);
      break
      default:
        break;
    }
   } else{
    navigate(`/queue-display/${machineId}`);
   }
  }

  const handleNavigateLoket =(machineId, slug) =>{
    switch (slug) {
      case allQueueMachine.BED_SLUG:
        navigate(`/admin-display/bed-config/${machineId}`);
        break;
      case allQueueMachine.ADMISSION_SLUG:
        navigate(`/admin-display/loket-config/${machineId}`);
        break;
      case allQueueMachine.POLICLINIC_SLUG:
        navigate(`/admin-display/policlinic-config/${machineId}`);
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Header />
      <div className="large-container">
        <div className="large-inner-container">
          <Row>
            <Col md={4}>
              <Row className="m-0 mb-3">
                <Col>
                  <span className="fw-bold fs-20">Layar Pasien</span>
                </Col>
              </Row>

              <Row className="m-0 mb-2">
                {queueMachine.allQueueMachine?.length &&
                  queueMachine.allQueueMachine.map((data, index) =>{
                    if(data.layar && data.layar.pasien == true){
                    return (
                      <Col
                        className="mb-2 d-flex flex-column text-center"
                        xs={6}
                        sm={4}
                        key={index}
                      >
                        <div
                          className="mb-1 home-card cursor-pointer"
                          onClick={() => navigate(`/patient-display/${data._id}`)}
                        >
                          <Image
                            src="/img/icon/icon-home-patient.svg"
                            height={"50px"}
                          />
                        </div>

                        <div>
                          <span className="fw-bold">{data.name}</span>
                        </div>
                      </Col>
                    )}})}
              </Row>
            </Col>
            <Col md={4}>
              <Row className="m-0 mb-3">
                <Col>
                  <span className="fw-bold fs-20">Layar TV</span>
                </Col>
              </Row>

              <Row className="m-0 mb-2">
                {queueMachine.allQueueMachine?.length &&
                  queueMachine.allQueueMachine.map((data, index) =>{
                    if(data.layar && data.layar.tv == true){
                      return(
                    <Col
                    className="mb-2 d-flex flex-column text-center"
                    xs={6}
                    sm={4}
                    key={index}
                  >
                    <div
                      className="mb-1 home-card cursor-pointer"
                      onClick={() => handleNavigate(data._id, data.slug)}
                    >
                      <Image src="/img/icon/icon-home-tv.svg" height={"50px"} />
                    </div>

                    <div>
                      <span className="fw-bold">{data.name}</span>
                    </div>
                  </Col>
                  )
                  }
                  })}
              </Row>
            </Col>
            <Col md={4}>
              <Row className="m-0 mb-3">
                <Col>
                  <span className="fw-bold fs-20">Layar Loket</span>
                </Col>
              </Row>

              <Row className="m-0 mb-2">
                {queueMachine.allQueueMachine?.length &&
                  queueMachine.allQueueMachine.map((data, index) => {
                    if(data.layar && data.layar.loket == true){
                      return( 
                      <>
                      {
                        data.slug == allQueueMachine.ADMISSION_SLUG ? 
                        <Col
                      className="mb-2 d-flex flex-column text-center"
                      xs={6}
                      sm={4}
                      key={index}
                    >
                      <div
                        className="mb-1 home-card cursor-pointer"
                        onClick={() => { handleShowModal(counter.counter, `/admin-display/display/${data._id}`)}
                      }
                      >
                        <Image
                          src="/img/icon/icon-home-counter.svg"
                          height={"50px"}
                        /> 
                      </div>

                      <div>
                        <span className="fw-bold">
                        {data.name}
                        </span>
                      </div>
                    </Col> : null

                      }
                    <Col
                      className="mb-2 d-flex flex-column text-center"
                      xs={6}
                      sm={4}
                      key={index}
                    >
                      <div
                        className="mb-1 home-card cursor-pointer"
                        onClick={() => {
                          handleNavigateLoket(data._id, data.slug)
                        }
                        }
                      >
                        <Image
                          src="/img/icon/icon-home-counter.svg"
                          height={"50px"}
                        /> 
                      </div>

                      <div>
                        <span className="fw-bold">
                        Config {data.name}
                        </span>
                      </div>
                    </Col>
                    </>

                    )}})}
              </Row>
            </Col>
          </Row>
          
        </div>
        <div className="text-center mt-2">
          <span>Aido Health All Right Reserved </span>
        </div>
      </div>
      {renderModal()}
      {renderModalWarning({modal: modalWarning, modalMessage:warningMessage, emptyModal})}
    </>
  );
};

export default Home;
