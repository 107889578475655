import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import {
  AdminDisplay,
  AdminDisplayPoliclinicConfig,
  Home,
  Login,
  PatientDisplay,
  PatientLablePrint,
  QueueDisplay,
  QueuePharmacyDisplay,
  QueuePoliclinicDisplay
} from "./pages";
import QueueAdmissionDisplay from "./pages/QueueDisplay/Admission";
import AdminDisplayLoketConfig from "./pages/AdminDisplay/ConfigAntrol";
import AdminDisplayBedConfig from "./pages/AdminDisplay/Bed";
import QueueBedDisplay from "./pages/QueueDisplay/Bed";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/patient-display/:machineId"
          element={
            <PrivateRoute>
              <PatientDisplay />
            </PrivateRoute>
          }
        />
        <Route
          path="/patient-display/print/:secret"
          element={
            <PrivateRoute>
              <PatientLablePrint />
            </PrivateRoute>
          }
        />
        <Route
          path="/queue-display/:machineId"
          element={
            <PrivateRoute>
              <QueueDisplay />
            </PrivateRoute>
          }
        />
         <Route
          path="/queue-display/pharmacy/:machineId"
          element={
            <PrivateRoute>
              <QueuePharmacyDisplay />
            </PrivateRoute>
          }
        />
        <Route
          path="/queue-display/admission/:machineId"
          element={
            <PrivateRoute>
              <QueueAdmissionDisplay />
            </PrivateRoute>
          }
        />
        <Route
          path="/queue-display/policlinic/:machineId"
          element={
            <PrivateRoute>
              <QueuePoliclinicDisplay />
            </PrivateRoute>
          }
        />
        <Route
          path="/queue-display/bed-config/:machineId"
          element={
            <PrivateRoute>
              <QueueBedDisplay />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin-display/policlinic-config/:machineId"
          element={
            <PrivateRoute>
              <AdminDisplayPoliclinicConfig />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin-display/loket-config/:machineId"
          element={
            <PrivateRoute>
              <AdminDisplayLoketConfig/>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin-display/bed-config/:machineId"
          element={
            <PrivateRoute>
              <AdminDisplayBedConfig/>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin-display/display/:machineId/:counterId"
          element={
            <PrivateRoute>
              <AdminDisplay />
            </PrivateRoute>
          }
        />
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />

        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
