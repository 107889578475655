export const doSyntax = {
    update : 'update',
    create : 'create',
    delete : 'delete',
    cancel : 'cancel'
  }

export const MULTI_SCREEN_CATEGORY = {
  BPJS: 'BPJS',
  NON_BPJS: 'Non-BPJS'
}

export const QUEUE_ITEM_NAME = {
  BPJS_KESEHATAN: "Antrian BPJS",
  PRIBADI: "Antrian Pribadi",
  ASURANSI: "Antrian Asuransi",
  PERUSAHAAN: "Antrian Perusahaan"
}