import axiosInstance from "../helpers/axios";
import { ERROR_RESPONSE, counterConstants } from "./constants";

export const getAllCounter = () => {
  return async (dispatch) => {
    dispatch({ type: counterConstants.GET_ALL_COUNTER_REQUEST });

    const res = await axiosInstance.get("/api/counter").catch((err) => {
      return dispatch({
        type: counterConstants.GET_ALL_COUNTER_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: counterConstants.GET_ALL_COUNTER_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const getCounter = (id) => {
  return async (dispatch) => {
    dispatch({ type: counterConstants.GET_ALL_COUNTER_REQUEST });

    const res = await axiosInstance.get(`/api/counter/${id}`).catch((err) => {
      return dispatch({
        type: counterConstants.GET_ALL_COUNTER_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: counterConstants.GET_ALL_COUNTER_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const getCounterPoli = (id) => {
  return async (dispatch) => {
    dispatch({ type: counterConstants.GET_ALL_COUNTER_POLI_REQUEST });

    const res = await axiosInstance.get('/api/counter/poli').catch((err) => {
      return dispatch({
        type: counterConstants.GET_ALL_COUNTER_POLI_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: counterConstants.GET_ALL_COUNTER_POLI_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const deleteCounter = (id, category) => {
  return async (dispatch) => {
  try {
    dispatch({ type: counterConstants.GET_ALL_COUNTER_REQUEST });

    const res = await axiosInstance.delete(`/api/counter/${id}${category ? `?category=${category}` : ''}`).catch((err) => {
      return dispatch({
        type: counterConstants.GET_ALL_COUNTER_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: counterConstants.GET_ALL_COUNTER_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }

    throw new Error('Error delete loket')
  } catch (error) {
    throw error
  }
  };
};

export const createCounter = (body) => {
  return async (dispatch) => {
    try {
      dispatch({ type: counterConstants.GET_ALL_COUNTER_REQUEST });

    const res = await axiosInstance.post("/api/counter/", body).catch((err) => {
       dispatch({
        type: counterConstants.GET_ALL_COUNTER_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE,
      });
      throw err
    });
    
    if (res.status === 200) {
      dispatch({
        type: counterConstants.GET_ALL_COUNTER_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
     throw new Error('Error create loket')
    } catch (error) {
      throw error
    }
  };
};

export const updateCounter = (id, body) => {
  return async (dispatch) => {
   try {
    dispatch({ type: counterConstants.GET_ALL_COUNTER_REQUEST });

    const res = await axiosInstance.put(`/api/counter/${id}`, body).catch((err) => {
      dispatch({
        type: counterConstants.GET_ALL_COUNTER_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE,
      });
      throw err
    });

    if (res.status === 200) {
      dispatch({
        type: counterConstants.GET_ALL_COUNTER_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }

    throw new Error('Error delete loket')
   } catch (error) {
    throw error
   }
  };
};
