import { retrieveLocalStorageItem } from "./localStorage";


const kioskRole = retrieveLocalStorageItem("configs");

let isAntrol;
let isMultiScreen;

if(kioskRole) {
  isAntrol = kioskRole?.isAntrol;
  isMultiScreen = kioskRole?.isMultiScreen;
}

export { kioskRole, isAntrol, isMultiScreen }