import "./index.css";
import { Button as Btn } from "react-bootstrap";

const Button = (props) => {
  return (
    <Btn
      className={props.className ? props.className : "button-control"}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.title}
    </Btn>
  );
};

export default Button;
