import React, { useRef, useEffect} from 'react';
import './index.css'; // Pastikan untuk mengimpor file CSS
import { Col, Row } from 'react-bootstrap';

const DropDownPoli = ({
    selectedValue,
    options,
    isOpen,
    setIsOpen,
    classNameOption,
    onChange,
    ...props
}) => {
    const dropdownRef = useRef(null);

    const handleSelect = (data) => {
        setIsOpen(false);
        if (onChange) {
            onChange(data);
        }
    };

    const handleOpen = () => {
        if(options && options.length > 0) {
            setIsOpen(!isOpen)
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Row ref={dropdownRef} className="m-0 mb-5 d-flex justify-content-center" onClick={() => handleOpen()}>
            <Col md={12} className="text-center">
                <div  className={props.className ? props.className : 'custom-dropdown'}>
                    <div
                        className={`select-toggle ${isOpen ? 'open' : ''} ${selectedValue ? 'selected' : ''}`}
                    >
                        {selectedValue
                            ? options.find((item) => item.value === selectedValue.value)?.label
                            : props.defaultOptionTitle}
                    </div>
                    {isOpen && (
                        <div className={(options && options?.length < 4) ? 'options-container single-option' : classNameOption}>
                            {props.defaultOption && (
                                <div
                                    className={`option ${!selectedValue ? 'selected' : ''}`}
                                    onClick={() => handleSelect('')}
                                >
                                    {props.defaultOptionTitle}
                                </div>
                            )}
                            {options?.length > 0 &&
                                options.map((data, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`option ${selectedValue === data.value ? 'selected' : ''} ${data?.disabled ? 'disabled' : ''}`}
                                            onClick={() => {
                                                if(!data?.disabled) handleSelect(data);
                                            }}
                                        >
                                            {data.label}
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default DropDownPoli;
