import React, { useEffect, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import Select from 'react-select';
import "./index.css";

const InputSelect = (props) => {
  const [selectedOptions, setSelectedOptions] = useState(props.selectedOptions);
  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    // Pass the selected options back to the parent component
    if(props.onChange) {
      props.onChange(selectedOptions);
    }
  };

  useEffect(() => {
    setSelectedOptions(props.defaultValue)
  }, [props.defaultValue]);

  return (
    <>
      <Select
        isMulti={props.isMulti}
        name={props.name}
        options={props.options}
        className={props.className}
        classNamePrefix="select"
        filterOption={props.filterOption}
        placeholder={props.placeholder}
        isClearable={props.isClearable}
        isSearchable={props.isSearchable}
        styles={props.styles}
        value={selectedOptions}
        onChange={handleSelectChange}
        isOptionDisabled={() => selectedOptions?.length >= 12}
      />
      {props.isSearch ? (
        <div className="d-flex justify-content-end input-div-search">
          <BsSearch className="input-search" />
        </div>
      ) : null}
    </>
  )
};

export default InputSelect;