import { counterConstants } from "../actions/constants";

const initState = {
  counter: null,
  counterPoli: null,
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case counterConstants.GET_COUNTER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case counterConstants.GET_COUNTER_SUCCESS:
      state = {
        ...state,
        counter: action.payload,
        loading: false,
      };
      break;
    case counterConstants.GET_COUNTER_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;
    case counterConstants.GET_ALL_COUNTER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case counterConstants.GET_ALL_COUNTER_SUCCESS:
      state = {
        ...state,
        counter: action.payload,
        loading: false,
      };
      break;
    case counterConstants.GET_ALL_COUNTER_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;
      case counterConstants.GET_ALL_COUNTER_POLI_REQUEST:
        state = {
          ...state,
          loading: true,
        };
        break;
      case counterConstants.GET_ALL_COUNTER_POLI_SUCCESS:
        state = {
          ...state,
          counterPoli: action.payload,
          loading: false,
        };
        break;
      case counterConstants.GET_ALL_COUNTER_POLI_FAILURE:
        state = {
          ...state,
          error: action.payload,
          loading: false,
        };
        break;
    default: 
      break;
  }
  return state;
};
