import HeaderDashboard from "../../../components/HeaderDashboard";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import PopupConfirmation from "../../../components/PopupConfirmation";
import Swal from "sweetalert2";
import { isAntrol } from "../../../helpers/kioskConfigs";
import { HiArrowSmLeft } from "react-icons/hi";
import { ERROR_RESPONSE } from "../../../actions/constants";
import { getQueueMachine, updateQueueMachine } from "../../../actions/queueMachine.actions";

export default function AdminDisplayBedConfig() {
  const { machineId } = useParams();
  const navigate = useNavigate();

  const {
    loading,
    error: queueMachineError,
  } = useSelector((state) => state.queueMachine);

  // Update Data State
  const [updateData, setUpdateData] = useState();
  // Empty Input Field Error State

  // Popup Confirmation State
  const [popup, setPopup] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQueueMachine(machineId))
      .then((data) => setUpdateData(data.dataPerPage))
      .catch((err) => {
        console.log(err);
      });
    // room for policlinic queue display on the client side
  }, [machineId]);

  useEffect(() => {
    if (queueMachineError) {
      Swal.fire({
        icon: "error",
        text: ERROR_RESPONSE.errorMessage,
      });
    }
  }, [queueMachineError]);

  const handleFunction = () => {
    if (!loading) {
      if (!updateData && updateData != 0) {
        Swal.fire({
          icon: "error",
          text: "Silahkan mengisi jumlah baris lebih dari 0",
        });
        setPopup(false)
        return
      }
      dispatch(updateQueueMachine(machineId, {dataPerPage: updateData}))
        .then(() => {
          Swal.fire({
            icon: "success",
            text: "Sukses mengganti jumlah baris",
            timer: 3000,
          });
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            text: "Gagal mmengganti jumlah baris",
          });
        });
      setPopup(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const renderPopUpConfrimation = () => {
    return (
      <PopupConfirmation
        show={popup}
        message={"Anda yakin ingin mengganti config jumlah kelas?"}
        handleClose={() => {
          if (!loading) {
            setPopup(false);
          }
        }}
        handleSave={() => {
          if (!loading) {
           handleFunction()
          }
        }}
      />
    );
  };

  return isAntrol ? (
    <div style={{ overflow: "hidden" }}>
      <HeaderDashboard />
      <div style={{ height: "75vh" }} className="large-container">
        <div style={{ height: "90%" }} className="large-inner-container">
          <Row style={{ overflow: "hidden", width: "100%", height: "100%" }}>
            <div
              style={{ width: "100%", display: "flex" }}
              className="overflow-medium"
            >
              <Col
                xs={3}
                style={{ position: "relative", height: "750px" }}
                className="locket-list-col"
              >
                <div onClick={handleBack} className="d-flex mb-4">
                  <HiArrowSmLeft className="fs-30 cl-green cursor-pointer" />
                  <div className="fw-bold fs-18 cursor-pointer mt-01 ml-04">
                    Kembali
                  </div>
                </div>
                <div className="header-content">
                  <span className="fw-bold fs-20">Detail Layar</span>
                </div>
                <div
                  style={{
                    overflow: "hidden",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <Col className="p-2 mt-3">
                    <span className="fw-semi-bold">
                      Tampil Jumlah Baris Dalam Satu Halaman
                    </span>
                    <Input
                      type="number"
                      placeholder={"Jumlah Kelas Kamar"}
                      onChange={(e) => setUpdateData(e.target.value)}
                      value={updateData}
                    />
                  </Col>
                  <Col className="d-flex justify-content-start p-2 pt-3">
                    <Button
                      title="Simpan"
                      className="button-save"
                      onClick={() => {
                        if (!loading) {
                          setPopup(true);
                        }
                      }}
                    />
                  </Col>
                </div>
              </Col>
            </div>
          </Row>
        </div>
        <div className="text-center mt-2">
          <span>Aido Health All Right Reserved </span>
        </div>
      </div>
      {renderPopUpConfrimation()}
    </div>
  ) : null;
}
