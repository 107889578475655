import { bedConstants } from "../actions/constants";

const initState = {
  bedList: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case bedConstants.GET_BED_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case bedConstants.GET_BED_LIST_SUCCESS:
      state = {
        ...state,
        bedList: action.payload,
        loading: false,
      };
      break;
    case bedConstants.GET_BED_LIST_FAILURE:
      state = {
        ...state,
        bedList: [],
        error: action.payload,
        loading: false,
      };
      break
    default: 
      break;
  }
  return state;
};
