import { useState, useEffect } from "react";
import "./index.css";

const RadioButton = (props) => {
  const { options, onChange, value } = props;
  const [selectedOption, setSelectedOption] = useState()
  const handleOptionChange = (option) => {
    setSelectedOption(option)
    onChange(option)
  }
  useEffect(() => {
    setSelectedOption(value)
  }, [value])
  return (
    <div className="radio-button-container">
      {options.map((option) => (
        <label key={option}>
          <input
            type="radio"
            value={option}
            checked={selectedOption === option}
            onChange={() => handleOptionChange(option)}
          />
          <span>
          {option}
          </span>
        </label>
      ))}
    </div>
  )
}

export default RadioButton;