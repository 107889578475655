import { Col, Image, Modal, Row } from "react-bootstrap";
import Button from "../Button";



export const renderModalWarning = ({modal, modalMessage, emptyModal}) => {
    return (
      <Modal
        size="md"
        show={modal}
        handleClose={() => emptyModal()}
        title="Konfirmasi"
      >
        
        <Row className="text-center" style={{paddingTop:'70px'}} >
          <Col>
            <Image src="/img/icon/icon-timedate.svg" height={"100px"} />
          </Col>
        </Row>

        <Row className="m-0 mb-5 text-center">
          <Col>
            <span>{modalMessage ?? 'Terjadi Error'}</span>
          </Col>
        </Row>

        <Row className="m-3">
          <Col>
            <Button title="Tutup" onClick={() => emptyModal()} />
          </Col>
        </Row>
      </Modal>
    );
  };