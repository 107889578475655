import React, { useEffect, useRef, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createQueueProcess, getQueueMachine } from "../../actions";
import Button from "../../components/Button";
import { encode as base64_encode } from "base-64";
import "./index.css";
import PatientLablePreview from "./Preview";
import { useReactToPrint } from 'react-to-print';
import Modal from "../../components/Modal";
import DropDownPoli from "../../components/DropDownPoli";
import { HiArrowSmLeft } from "react-icons/hi";

const PatientDisplay = () => {
  const { machineId } = useParams();
  let componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      setTimeout(() => {
        setLoading(false)
      }, 2000);
    },
  });

  const queueProcess = useSelector((state) => state.queueProcess);
  const queueMachine = useSelector((state) => state.queueMachine);
  const [dataEncoded, setDataEncoded] = useState();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modalPoli, setModalPoli] = useState(false)
  const [mapPoli, setMapPoli] = useState(false)
  const [poli, setPoli] = useState()
  const [payerId, setPayerId] = useState('')
  const [loading, setLoading] = useState(false);
  const hospitalLogo = localStorage.getItem("logo");

  useEffect(() => {
    dispatch(getQueueMachine(machineId)).catch((err) => {
      console.log(err);
    });
  }, [dispatch, machineId]);

  useEffect(() => {
    if (queueProcess.createQueueProcess) {
      const encodedData = base64_encode(
        JSON.stringify(queueProcess.createQueueProcess)
      );
      setDataEncoded(encodedData);
      // window.open(`/patient-display/preview/${encodedData}`, "_blank");

    }
  }, [queueProcess.createQueueProcess]);

  const handleCreate = async (itemId) => {
    let body = {
      queueMachine: machineId,
      queueItem: itemId,
      poliId: poli.value
    };
    if (!queueProcess.createLoading) {
    return new Promise((resolve, reject) => {
      dispatch(createQueueProcess(body)).then((res) => {
      setTimeout(() => {
        resolve()
      }, 1000);
    })
    .catch((err) => {
      // open modal fail to get kiosk queue number
      setModal(true)
    }
    );
    });
    }
  };

  const handleCreateAndPrint = (itemId) => {
    setLoading(true)
    handleCreate(itemId).then( () => {
      handlePrint()
    }).catch((error) => {
      console.error("Erorr dispatching: ", error);
      setLoading(false)
    })
  }

  const renderModal = () => {
    return (
      <Modal
        size="md"
        show={modal}
        handleClose={() => setModal(false)}
        title=""
      >
        <Row className="m-0 text-center">
          <Col>
            <Image src="/img/icon/icon-timedate.svg" height={"100px"} />
          </Col>
        </Row>

        <Row className="m-0 mb-5 text-center">
          <Col>
            <span>Gagal mengambil nomor antrian kiosk</span>
          </Col>
        </Row>

        <Row className="m-0">
          <Col>
            <Button title="Tutup" onClick={() => setModal(false)} />
          </Col>
        </Row>
      </Modal>
    );
  };

  const renderPoli = () =>{
    return <>
      <div onClick={()=>{
        setPoli()
        setModalPoli(false)
      }} className="mb-4" style={{fontSize:'48px', display:'flex', alignItems:'center'}}>
                <HiArrowSmLeft className="fs-60 cl-green cursor-pointer" />
                <div className="fw-bold cursor-pointer mt-01 ml-04">Kembali</div>
      </div>
       <Row className="m-0 d-flex justify-content-center">
       <Col md={9} className="text-center">
        <DropDownPoli
          value={poli}
          onChange={(e) => {
              setPoli(e)
          }
          }
          selectedValue={poli}
          setIsOpen={setMapPoli}
          isOpen={mapPoli}
          defaultOptionTitle="- Pilih Poliklinik Rawat jalan -"
          options={queueMachine.queueMachine.queuePoliSpecial?.item?.map((data)=>{
            return{
              value: data.uuid,
              label: data.name
            }
          })}
          className="custom-dropdown"
          classNameOption="options-container-doctor"
        />  
        </Col>
        </Row>
        
        <Row className="m-0 d-flex justify-content-center">
        <Col md={9} className="text-center">
          <Button
           className={`${poli ?'custom-dropdown':'button-control-h-default-poli-false'}`}
           title={'Cetak Nomor Antrian'}
           disabled={poli? false:true}
           onClick={() => {
              if (!loading) {
                 handleCreateAndPrint(payerId)
              }
           }
             }
             />
             </Col>
        </Row>
    </>
  }

  return (
    <>
    <div className="patient-bg">
      <div className="patient-header">
        <Row>
          <Col className={ hospitalLogo ? "text-start" : "text-center"}>
              <Image src={hospitalLogo ? hospitalLogo : "/img/logo/logo-login-hospita.svg"} height={ hospitalLogo ? "120px" : "80px"} />
          </Col>
        </Row>
      </div>
      <div className="patient-container"  style={{justifyContent: modalPoli ? 'start' : 'center'}}>
        {modalPoli ? renderPoli() : 
       queueMachine.queueMachine?.queueItems?.length &&
          queueMachine.queueMachine.queueItems.map((data, index) => (
            <Row className="m-0 mb-4 d-flex justify-content-center" key={index}>
              <Col md={9} className="text-center">
                <Button
                  className={(queueMachine.queueMachine?.queueItems?.length < 4 ? "button-control-h-default" : (queueMachine.queueMachine?.queueItems?.length < 5 ? "button-control-h-100" : "button-control-h-80"))}
                  title={data.name}
                  onClick={() =>{
                    setModalPoli(true)
                    setPayerId(data._id)
                  }}
                />
              </Col>
            </Row>
          ))
        }
      </div>
      <div>
      <div style={{ display: "none" }}>
        <PatientLablePreview ref={componentRef} data={dataEncoded} />
      </div>
        {/* <button onClick={() => handlePrint()}>Print this out!</button> */}
      </div>
    </div>
    {renderModal()}
    </>
  );
};

export default PatientDisplay;
