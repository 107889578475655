import { Carousel, CarouselItem, Col, Row } from "react-bootstrap";
import Topbar from "../../../components/Topbar";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getAllBed, getQueueMachine } from "../../../actions";

export default function QueueBedDisplay() {
  const { machineId } = useParams();

  const statusBed = [
    { name: "Jumlah Bed", value: "jumlah_bed" },
    { name: "Tersedia", value: "tersedia" },
    { name: "Terisi", value: "terisi" },
  ];

  const kelas = { name: `Jenis`, value: "bpjs_code" };

  const { bedList } = useSelector((state) => state.bed);
  const { queueMachine } = useSelector((state) => state.queueMachine);

  const dispatch = useDispatch();

  //loop get data every 30 minutes
  useEffect(() => {
    function timeInterval() {
      console.log("check loop");
      dispatch(getAllBed(machineId));
      dispatch(getQueueMachine(machineId));
    }
    timeInterval();
    const id = setInterval(timeInterval, 1800000);
    return () => clearInterval(id);
  }, [machineId]);

  const mappedQueue = () => {
    const carouselItems = [];

    for (
      let i = 0;
      i < Math.ceil(bedList.length / (queueMachine?.dataPerPage ?? 9));
      i++
    ) {
      carouselItems.push(
        bedList.slice(
          i * (queueMachine?.dataPerPage ?? 9),
          (i + 1) * (queueMachine?.dataPerPage ?? 9)
        )
      );
    }
    return (
      <>
        <div className="words-bed">
          <div className="words-splitter">{kelas.name}</div>
          {statusBed.map((data) => (
            <Col>{data.name}</Col>
          ))}
        </div>
        {bedList.length > 0 ? (
          <Carousel
            indicators={true}
            nextIcon={null}
            prevIcon={null}
            variant="dark"
            interval={5000}
            style={{ paddingBottom: "50px" }}
          >
            {carouselItems.map((dataCarousel) => {
              return (
                <CarouselItem
                  style={{
                    padding: 0,
                  }}
                >
                  {dataCarousel.map((dataInside) => {
                    return (
                      <div style={{ display: "flex", margin: 0 }}>
                        <div
                          className="words-splitter"
                          style={{ backgroundColor: "black", color: "white" }}
                        >
                          {dataInside[kelas.value]}
                        </div>
                        {statusBed.map((data) => {
                          return (
                            <Col
                              style={{
                                backgroundColor: "white",
                                color: "black",
                                fontWeight: 700,
                              }}
                              className="text-center p-3"
                            >
                              {dataInside[data.value]}
                            </Col>
                          );
                        })}
                      </div>
                    );
                  })}
                </CarouselItem>
              );
            })}
          </Carousel>
        ) : (
          <div
            className="words-splitter"
            style={{
              backgroundColor: "white",
              color: "black",
              width: "100%",
              padding: "50px",
            }}
          >
            Tipe kelas belum ada
          </div>
        )}
      </>
    );
  };

  return (
    <div className="queue-bg">
      <Col>
        <Topbar />
        <Row className="bot">
          <div className="text-center fw-bold">
            INFORMASI KETERSEDIAAN TEMPAT TIDUR
          </div>
          <div className="table px-5 border-black">{mappedQueue()}</div>
        </Row>
      </Col>
    </div>
  );
}
