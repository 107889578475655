import axiosInstance from "../helpers/axios";
import { ERROR_RESPONSE, queueMachineConstants } from "./constants";

export const getAllQueueMachine = () => {
  return async (dispatch) => {
    dispatch({ type: queueMachineConstants.GET_ALL_QUEUE_MACHINE_REQUEST });

    const res = await axiosInstance.get("/api/queue-machine").catch((err) => {
      return dispatch({
        type: queueMachineConstants.GET_ALL_QUEUE_MACHINE_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: queueMachineConstants.GET_ALL_QUEUE_MACHINE_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const getQueueMachine = (id) => {
  return async (dispatch) => {
    dispatch({ type: queueMachineConstants.GET_QUEUE_MACHINE_REQUEST });

    const res = await axiosInstance.get(`/api/queue-machine/${id}`).catch((err) => {
      return dispatch({
        type: queueMachineConstants.GET_QUEUE_MACHINE_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: queueMachineConstants.GET_QUEUE_MACHINE_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const updateQueueMachine = (id, payload) =>{
  return async (dispatch) => {
    dispatch({ type: queueMachineConstants.UPDATE_QUEUE_MACHINE_REQUEST });

    const res = await axiosInstance.put(`/api/queue-machine/${id}`, payload).catch((err) => {
      return dispatch({
        type: queueMachineConstants.UPDATE_QUEUE_MACHINE_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: queueMachineConstants.UPDATE_QUEUE_MACHINE_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
}
