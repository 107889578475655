import axiosInstance from "../helpers/axios";
import { ERROR_RESPONSE, policlinicConfigConstants } from "./constants";

export const getPoliclinicScreen = (id) => {
  return async (dispatch) => {
    dispatch({ type: policlinicConfigConstants.GET_POLICLINIC_SCREEN_REQUEST });

    const res = await axiosInstance.get(`/api/queue-item/${id}`).catch((err) => {
      return dispatch({
        type: policlinicConfigConstants.GET_POLICLINIC_SCREEN_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE
      });
    });

    if(res.status === 200) {
      dispatch({
        type: policlinicConfigConstants.GET_POLICLINIC_SCREEN_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const createPoliclinicScreen = (body) => {
  return async (dispatch) => {
    dispatch({ type: policlinicConfigConstants.CREATE_POLICLINIC_SCREEN_REQUEST });

    const res = await axiosInstance.post(`/api/queue-item/`, body).catch((err) => {
      return dispatch({
        type: policlinicConfigConstants.CREATE_POLICLINIC_SCREEN_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE
      });
    });

    if(res.status === 200) {
      dispatch({
        type: policlinicConfigConstants.CREATE_POLICLINIC_SCREEN_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const updatePoliclinicScreen = (id, body) => {
  return async (dispatch) => {
    dispatch({ type: policlinicConfigConstants.UPDATE_POLICLINIC_SCREEN_REQUEST });

    const res = await axiosInstance.put(`/api/queue-item/${id}`, body).catch((err) => {
      return dispatch({
        type: policlinicConfigConstants.UPDATE_POLICLINIC_SCREEN_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE
      });
    });

    if(res.status === 200) {
      dispatch({
        type: policlinicConfigConstants.UPDATE_POLICLINIC_SCREEN_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const deletePoliclinicScreen = (id) => {
  return async (dispatch) => {
    dispatch({ type: policlinicConfigConstants.DELETE_POLICLINIC_SCREEN_REQUEST });

    const res = await axiosInstance.delete(`/api/queue-item/${id}`).catch((err) => {
      return dispatch({
        type: policlinicConfigConstants.DELETE_POLICLINIC_SCREEN_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE
      });
    });

    if(res.status === 200) {
      dispatch({
        type: policlinicConfigConstants.DELETE_POLICLINIC_SCREEN_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const getAllDoctorPoli = () => {
  return async (dispatch) => {
    dispatch({ type: policlinicConfigConstants.GET_ALL_DOCTOR_POLI_LIST_REQUEST });

    const res = await axiosInstance.get(`/api/outbound/get-doctor-poli`).catch((err) => {
      return dispatch({
        type: policlinicConfigConstants.GET_ALL_DOCTOR_POLI_LIST_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE
      });
    });

    if(res.status === 200) {
      dispatch({
        type: policlinicConfigConstants.GET_ALL_DOCTOR_POLI_LIST_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  }
} 

export const getAllPoli = () => {
  return async (dispatch) => {
    dispatch({ type: policlinicConfigConstants.GET_ALL_POLI_LIST_REQUEST });

    const res = await axiosInstance.get(`/api/outbound/get-poli`).catch((err) => {
      return dispatch({
        type: policlinicConfigConstants.GET_ALL_POLI_LIST_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE
      });
    });

    if(res.status === 200) {
      dispatch({
        type: policlinicConfigConstants.GET_ALL_POLI_LIST_SUCCESS,
        payload: res.data?.response,
      });

      return res.data;
    }
  }
} 

export const getAllDoctorQueueTableScreen = (query) => {
  const { machineId, queueItemId } = query;
  return async (dispatch) => {
    dispatch({ type: policlinicConfigConstants.GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_REQUEST });

    const res = await axiosInstance.get(`/api/queue-process/poli?machineId=${machineId}&queueItemId=${queueItemId}`).catch((err) => {
      return dispatch({
        type: policlinicConfigConstants.GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE
      });
    });

    if (res.status === 200) {
      dispatch({
        type: policlinicConfigConstants.GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_SUCCESS,
        payload: res.data
      });

      return res.data;
    }
  }
}